import React, { createContext, useState } from "react";
import RoleService from "../services/RoleService";
import { Toast } from "../components/common/Toast";

export const RoleContext = createContext("RoleContext");

const RoleContextProvider = ({ children }) => {
    const [roleList, setRoleList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingRoleList, setLoadingRoleList] = useState(false);
    const [roleListErrorMsg, setRoleListErrorMsg] = useState("");

    const getRoleList = async (page = 0, size = 10) => {
        try {
            setLoadingRoleList(true);
            setRoleListErrorMsg("");
            const response = await RoleService.getRolesList(page, size);
            setRoleList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingRoleList(false);
        } catch (error) {
            setLoadingRoleList(false);
            const message = error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : error.response.data
                : error.message;
            setRoleListErrorMsg(message);
            Toast("error", "Error", message);
        }
    };

    return (
        <RoleContext.Provider
            value={{
                getRoleList,
                roleList,
                totalElements,
                loadingRoleList,
                roleListErrorMsg
            }}
        >
            {children}
        </RoleContext.Provider>
    );
};

export default RoleContextProvider;
